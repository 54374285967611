import type { AvailabilityDay, Day } from '@index/interfaces';
import { Availability, TimeRange } from '@index/interfaces';

import { GthModel } from './model';

// eslint-disable-next-line max-len
export type SrvAvailabilitDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export class SrvAvailabilityModel extends GthModel<Availability> {
  get dateSpecificAvailability() {
    return this.model?.dateSpecificAvailability || [];
  }

  get weeklyAvailability() {
    return (
      this.model.weeklyAvailability || {
        monday: { isAvailable: false, timeRanges: [] },
        tuesday: { isAvailable: false, timeRanges: [] },
        wednesday: { isAvailable: false, timeRanges: [] },
        thursday: { isAvailable: false, timeRanges: [] },
        friday: { isAvailable: false, timeRanges: [] },
        saturday: { isAvailable: false, timeRanges: [] },
        sunday: { isAvailable: false, timeRanges: [] },
      }
    );
  }

  hasSetAvailability() {
    return this.isAvailableInWeek() || this.dateSpecificAvailability?.length > 0;
  }

  constructor(id: string, model: Availability) {
    super(id, model);
  }

  setWeeklyAvailability(day: Day, isAvailable: boolean, timeRanges: TimeRange[]) {
    if (!this.model.weeklyAvailability) {
      this.model.weeklyAvailability = {
        monday: { isAvailable: false, timeRanges: [] },
        tuesday: { isAvailable: false, timeRanges: [] },
        wednesday: { isAvailable: false, timeRanges: [] },
        thursday: { isAvailable: false, timeRanges: [] },
        friday: { isAvailable: false, timeRanges: [] },
        saturday: { isAvailable: false, timeRanges: [] },
        sunday: { isAvailable: false, timeRanges: [] },
      };
    }
    if (this.model.weeklyAvailability.hasOwnProperty(day)) {
      this.model.weeklyAvailability[day] = { isAvailable, timeRanges: [...timeRanges] };
    }
  }

  setDateSpecificAvailability(date: string, timeRanges: TimeRange[]) {
    if (!this.model.dateSpecificAvailability) {
      this.model.dateSpecificAvailability = [];
    }
    const existingDateIndex = this.model
      .dateSpecificAvailability.findIndex((item) => item.date === date);

    if (existingDateIndex !== -1) {
      // Update existing date-specific availability
      this.model.dateSpecificAvailability[existingDateIndex] = {
        date,
        timeRanges: [...timeRanges],
      };
    } else {
      // Add new date-specific availability
      this.model.dateSpecificAvailability.push({ date, timeRanges: [...timeRanges] });
    }
  }

  removeDateSpecificAvailability(date: SrvAvailabilitDay) {
    if (this.model.dateSpecificAvailability) {
      this.model.dateSpecificAvailability = this.model.dateSpecificAvailability.filter(
        (item) => item.date !== date,
      );
    }
  }

  isAvailableInWeek() {
    const weeklyAvailability = this.model?.weeklyAvailability;

    // Check if any day in weeklyAvailability has isAvailable set to true
    let isAvailable = false;
    // eslint-disable-next-line guard-for-in
    for (const dayStr in weeklyAvailability) {
      const day = dayStr as SrvAvailabilitDay;
      if (weeklyAvailability.hasOwnProperty(day) &&
        weeklyAvailability[day].hasOwnProperty('isAvailable') &&
        weeklyAvailability[day].isAvailable
      ) {
        isAvailable = true;
        break;
      }
    }

    return isAvailable;
  }

  availableOnDay(day: string) {
    const availability = this.weeklyAvailability[day] as AvailabilityDay;
    if (!availability) {
      return false;
    }
    return availability.isAvailable;
  }
}
